.container-chart-detailed {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr); 
}

.container-chart-simplified {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr); 
}

@media (max-width: 800px) {
    .container-chart-detailed {
        grid-template-columns: repeat(1, 1fr); 
    }
}

@media (max-width: 600px) {
    .container-chart-detailed {
        grid-template-columns: repeat(1, 1fr); 
    }
}

@media (max-width: 850px) {
    .container-chart-simplified {
        grid-template-columns: repeat(1, 1fr); 
    }
}

@media (max-width: 600px) {
    .container-chart-simplified {
        grid-template-columns: repeat(1, 1fr); 
    }
}