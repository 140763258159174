.content-items {
    display: grid;
    width: 100%;
    gap: 0px;
}

@media (min-width: 640px) {
    .content-items {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (min-width: 768px) {
    .content-items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1024px) {
    .content-items {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1280px) {
    .content-items {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1400px) {
    .content-items {
        grid-template-columns: repeat(5, 1fr);
    }
}


@media (min-width: 1800px) {
    .content-items {
        grid-template-columns: repeat(6, 1fr);
    }
}